/* You can add global styles to this file, and also import other style files */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "~@angular/material/prebuilt-themes/purple-green.css";

@import "./assets/theme/colors";
@import "./assets/theme/fonts";
@import "@angular/cdk/overlay-prebuilt.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";


div#root {
  height: 100%;
}

html,
body {
  font-family: "Satoshi-Regular", sans-serif;
  font-size: 17px;
  height: 100%;
  line-height: 1.4;
  /********** For vertical bar*********/
  min-height: 100vh;
  // overflow-x: hidden;
  background-color: $viu-white;
  scroll-behavior: smooth;
  margin: 0px; 
}

// html {
//   position: initial !important;
//   overflow-y: initial !important;
// }

// html.cdk-global-scrollblock body {
//   overflow: hidden;
// }
.cdk-overlay-container {
  // overflow: hidden;
  .cdk-global-overlay-wrapper {
    // pointer-events: auto;
    // overflow: auto;
  }
}

// // Overlay backdrop styling
// .overlay-backdrop {
//   background-color: rgba(0, 0, 0, 0.5);
//   border: 2px solid red;
// }

// // Overlay panel styling
// .overlay-panel {
//   display: flex;
//   flex-direction: column;
//   background-color: #ffffff;
//   padding: 20px;
// }

// .cdk-global-overlay-wrapper {
//   pointer-events: auto;
//   overflow: auto;
// }

// .cdk-global-overlay-wrapper::before {
//   content: '';
//   display: inline-block;
//   height: 100%;
//   white-space: nowrap;
// }

// .cdk-overlay-pane {
//   display: inline-block;
//   position: relative;
//   text-align: left;
//   white-space: normal;
// }


.content-title-text {
  font-weight: 700;
  letter-spacing: -0.01em;
  font-size: 24px;
  line-height: 32px;
  color: #0c2225;
}




// TABLES

.table thead th {
  vertical-align: bottom;
  border-top: 1px solid #dee2e6;
  border-bottom: none;
  font-size: 15px;
  line-height: 22px;
  color: #626e77;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
}

tr {
  border-bottom: 1px solid #e1e5e8;
}

th {
  border-bottom: none;
}

td {
  height: 50px;
  color: #0c2225;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}




@media screen and (max-width: 600px) {
  table {
    border: 0;
    background-color: #f7f7f7;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    margin-bottom: .625em;
  }

  table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}




.modal {
  background: rgba(12, 34, 37, 0.95);
}

.modal-content {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent !important;
}
