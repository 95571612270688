$viu-black: #1e2b29;
$viu-blue-black: #00132f;
$viu-semi-dark-gray: #e5e5e5;
$viu-dark-gray: #626e77;
$viu-gray-10: #f7f7f7;
$viu-gray-20: #f1f1f1;
$viu-gray-40: #e1e5e8;
$viu-gray-light: #79838b;
$viu-black-gray-80: #b5bdc5;
$viu-green: #1b5750;
$viu-anchor-green: #123f39;
$viu-aqua-blue: #07b7ca;
$viu-red: red;
$viu-peaceful-blue: #00a1b2;
$viu-blue: #008095;
$viu-white: #ffffff;
$viu-dark-black: #0c2225;
$viu-other-mint: #e1f1ef;
$viu-green-md: #e9efee;
