@font-face {
  font-family: 'Satoshi-Light';
  src: url('../fonts/Satoshi/Satoshi-Light.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-Light.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('../fonts/Satoshi/Satoshi-LightItalic.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-LightItalic.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-Regular.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Italic';
  src: url('../fonts/Satoshi/Satoshi-Italic.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-Italic.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-Medium.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('../fonts/Satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-MediumItalic.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-Bold.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('../fonts/Satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-BoldItalic.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('../fonts/Satoshi/Satoshi-Black.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-Black.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('../fonts/Satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
  url('../fonts/Satoshi/Satoshi-BlackItalic.woff') format('woff'),
  url('../fonts/Satoshi/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Glacial-Indifference";
  src: local("Glacial-Indifference"),
  url("../fonts/glacial-indifference/GlacialIndifference-Regular.otf") format("otf");
}
